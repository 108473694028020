* {
  box-sizing: border-box; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, sub, sup, tt, var, u, i, center, strong, b, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  line-height: 1em;
  font-weight: 400;
  vertical-align: baseline;
  font-family: 'Roboto', helvetica, arial, sans-serif;
  list-style-type: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.025); }

main, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

em, i {
  font-style: italic;
  font-family: inherit; }

strong, b {
  font-weight: bold;
  font-family: inherit; }

small {
  font-family: inherit; }

a {
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
  font-family: inherit; }

span {
  font-family: inherit; }

input, select, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border: 0 none;
  padding: 3px;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  outline: none; }

button {
  cursor: pointer;
  background: none;
  border: 0 none;
  font-family: inherit;
  font-size: inherit;
  outline: none; }

select::-ms-expand {
  /* IE 10 select dropdown fix */
  display: none; }

/* Main basic layout */
.content {
  width: 100%;
  max-width: 100%;
  padding: 0 3rem; }

.flex {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-column {
  flex-direction: column; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-center {
  align-items: center;
  justify-content: center; }

.flex-stretch {
  align-items: stretch; }

.flex-space-between {
  justify-content: space-between; }

.flex-wrap {
  flex-wrap: wrap; }

.full {
  flex: 1 1 100%; }

.half {
  flex: 1 1 50%; }

.third {
  flex: 1 1 33.33%; }

.two-third {
  flex: 1 1 66.66%; }

.quarter {
  flex: 1 1 25%; }

.three-quarter {
  flex: 1 1 75%; }

.fifth {
  flex: 1 1 20%; }

.auto {
  flex: 1 1 auto; }

.no-grow {
  flex-grow: 0; }

.no-shrink {
  flex-shrink: 0; }

/* Media queries for responsive design layout */
@media only screen and (max-width: 1199px) {
  .content {
    max-width: 100%; } }

@media only screen and (max-width: 880px), only screen and (max-width: 1000px) and (orientation: portrait) {
  .flex-row {
    flex-direction: column;
    -webkit-flex-direction: column;
    -ms-flex-direction: column; }
  .flex-row-reverse {
    flex-direction: column-reverse;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse; }
  .full, .half, .third, .two-third, .quarter, .three-quarter, .fifth {
    flex: 1 1 auto; } }

@keyframes fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fade-out {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes slide-in-top {
  0% {
    transform: translate(0, -4rem); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-top {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, -4rem); } }

@keyframes slide-in-bottom {
  0% {
    transform: translate(0, 4rem); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-bottom {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(0, 4rem); } }

@keyframes slide-in-left {
  0% {
    transform: translate(-4rem, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-left {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(-4rem, 0); } }

@keyframes slide-in-right {
  0% {
    transform: translate(4rem, 0); }
  100% {
    transform: translate(0, 0); } }

@keyframes slide-out-right {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(4rem, 0); } }

@keyframes slide-fade-in-top {
  0% {
    opacity: 0;
    transform: translate(0, -4rem); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-top {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(0, -4rem); } }

@keyframes slide-fade-in-bottom {
  0% {
    opacity: 0;
    transform: translate(0, 4rem); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-bottom {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(0, 4rem); } }

@keyframes slide-fade-in-left {
  0% {
    opacity: 0;
    transform: translate(-4rem, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-left {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(-4rem, 0); } }

@keyframes slide-fade-in-right {
  0% {
    opacity: 0;
    transform: translate(4rem, 0); }
  100% {
    opacity: 1;
    transform: translate(0, 0); } }

@keyframes slide-fade-out-right {
  0% {
    opacity: 1;
    transform: translate(0, 0); }
  100% {
    opacity: 0;
    transform: translate(4rem, 0); } }

@keyframes twist-in {
  0% {
    transform: rotateY(-90deg); }
  100% {
    transform: rotateY(0deg); } }

/* =========== Primary Palette === */
/* ========== Secondary Palette === */
/* ====== Grey Palette === */
/* ================================================================= Push Auto === */
/* =========================================================== Pseudo Elements === */
/* ========================================================== Responsive Ratio === */
/* ========================================================= Input Placeholder === */
/* ============================================================= Media Queries === */
/* =================================================================== Z Index === */
/* =============================================== Cheat Hardware Acceleration === */
/* ================================================================== Truncate === */
/* ================================================================ Visibility === */
/* ===================================================== Retina Display Images === */
/* ================================================================== Position === */
/* =================================================================== Convert === */
/* ================================================================== Pre View === */
/* ================================================================== In View === */
html {
  font-size: calc(1em + 0.1vw); }
  @media only screen and (max-width: 1600px) {
    html {
      font-size: 1em; } }

body {
  font-size: 1em;
  color: #333;
  background: #f9f9f9; }

.loading svg {
  width: 1.25rem;
  height: 1.25rem;
  z-index: 30; }

.loading circle {
  fill: none;
  stroke: #fff;
  stroke-width: 9;
  stroke-dasharray: 280;
  stroke-dashoffset: 280;
  stroke-linecap: round;
  animation: loading 2s linear both infinite;
  animation-delay: .34s; }

@keyframes loading {
  0% {
    stroke-dashoffset: 280; }
  100% {
    stroke-dashoffset: -220; } }

/* ==================================================== Splash Screen Elements === */
.splash-screen {
  backface-visibility: hidden;
  perspective: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 400s ease-in-out, transform 400ms ease-in-out;
  background: #f9f9f9;
  perspective: 50rem;
  animation: fade-in 200ms cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .splash-screen .splash-screen__logo {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 12em;
    height: 12em;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
    transition: transform 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19); }
    .splash-screen .splash-screen__logo img {
      width: 6rem;
      transform: scale(1);
      transition: transform 400ms ease-in-out; }
  .splash-screen.exiting {
    opacity: 0; }
    .splash-screen.exiting .splash-screen__logo {
      transform: rotateY(90deg); }

/* ==================================================================== Dialog === */
.dialog {
  backface-visibility: hidden;
  perspective: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 800000;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms ease-in-out; }
  .dialog .dialog__content {
    background: #fff;
    position: relative;
    padding: 2rem 3rem;
    min-width: 20rem;
    text-align: center;
    transform: translate(0, -100%);
    transition: transform 200ms ease-in-out; }
    .dialog .dialog__content .dialog__title {
      font-size: 1.25em;
      color: #333;
      font-weight: 500;
      margin-bottom: 0.92rem; }
    .dialog .dialog__content .dialog__description p {
      font-weight: 300;
      color: #333;
      margin: 0 auto;
      max-width: 35rem;
      line-height: 1.4em;
      font-size: 0.925em; }
    .dialog .dialog__content .dialog__comment textarea {
      border: solid 1px #666666;
      width: 100%;
      max-width: 30rem;
      margin: 1rem 0 auto;
      height: 10rem;
      padding: 1rem; }
  .dialog .actions__buttons {
    margin-top: 1.5rem; }
  .dialog .actions__buttons .button {
    margin: 0 0.25rem; }
  .dialog[data-open="true"] {
    opacity: 1;
    pointer-events: all; }
    .dialog[data-open="true"] .dialog__content {
      transform: translate(0, 0); }

.notification {
  backface-visibility: hidden;
  perspective: 1000;
  position: fixed;
  top: auto;
  right: auto;
  bottom: 2rem;
  left: 50%;
  z-index: 900000;
  background: #333333;
  border-radius: 3rem;
  padding: 1rem 2rem;
  opacity: 0;
  transform: translate(-50%, 3rem);
  transition: opacity 120ms ease-in-out, transform 120ms ease-in-out;
  pointer-events: none; }
  .notification[data-visible="true"] {
    opacity: 1;
    transform: translate(-50%, 0);
    pointer-events: all; }
  .notification p {
    color: #fff;
    font-size: 0.875em;
    font-weight: 500; }

/* =================================================================== Buttons === */
.button {
  font-size: 0.9375em;
  color: #fff;
  background: #e2211c;
  border-radius: 4rem;
  min-width: 10rem;
  height: 2.25rem;
  text-align: center;
  padding: 0 1rem;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  transition: background 120ms linear; }
  .button:hover {
    background: #b51a16; }
  .button:active {
    background: #9e1714; }
  .button .icon {
    position: absolute;
    right: 0.75rem;
    width: 1.25rem;
    top: 50%;
    transform: translate(0, -50%); }
    .button .icon .loading {
      width: 1.25rem;
      height: 1.25rem;
      margin-right: 1em; }
      .button .icon .loading svg {
        width: 1.25rem; }

.grey-button {
  flex: 0 0 auto;
  height: 2.35rem;
  padding: 0 1rem;
  border-radius: 0.3rem;
  background: #eee;
  text-align: center;
  font-size: 0.875em;
  font-weight: 300;
  border: solid 1px #666666; }
  .grey-button:hover {
    background: #e1e1e1; }
  .grey-button:active {
    background: #d5d5d5; }

/* ==================================================================== Inputs === */
.input {
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 0.875rem; }
  .input label {
    flex: 0 0 8.75rem;
    font-size: 1em;
    color: #333;
    font-weight: 300; }
    .input label small {
      display: block;
      font-size: 0.75rem;
      font-weight: 300;
      line-height: 1.6em; }
  .input.textarea, .input.file {
    align-items: flex-start; }
    .input.textarea label, .input.file label {
      padding-top: 0.65rem; }
  .input input[type="text"], .input input[type="email"],
  .input input[type="password"], .input input[type="number"], .input input[type="date"] {
    width: 100%;
    flex: 1 1 100%;
    height: 2.35rem;
    border-radius: 4px;
    border: solid 1px #666666;
    background: #fff;
    padding: 0 1em;
    min-width: 0;
    font-size: 0.875em;
    font-weight: 300;
    color: #333; }
  .input input[type="text"]:disabled {
    background: #ddd; }
  .input .select {
    width: 100%;
    flex: 1 1 100%;
    border-radius: 0.3rem;
    border: solid 1px #666666;
    background: #fff;
    min-width: 0;
    align-items: stretch;
    position: relative;
    transition: background-color 120ms linear; }
    .input .select:hover {
      background: #f6f6f6; }
    .input .select .dropdown {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
      background: #eeeeee;
      width: 2rem;
      border-radius: 0 0.3rem 0.3rem 0;
      height: 2.35rem;
      pointer-events: none; }
      .input .select .dropdown img {
        width: 0.45rem; }
    .input .select select {
      width: 100%;
      background: none;
      font-size: 0.875em;
      font-weight: 300;
      color: #333;
      cursor: pointer;
      padding-right: 2rem;
      padding-left: 1rem;
      height: 2.35rem; }
  .input .radios {
    flex: 1 1 100%;
    align-items: center; }
    .input .radios .radio {
      position: relative;
      align-items: center;
      margin-right: 1rem; }
      .input .radios .radio:last-child {
        margin-right: 0; }
      .input .radios .radio input {
        -moz-appearance: radio;
        -webkit-appearance: radio;
        flex: 0 0 1rem;
        padding: 0;
        width: 1rem;
        height: 1rem;
        margin-right: 0.35rem;
        border-radius: 50%;
        background: #fff;
        position: relative;
        border: solid 1px #333;
        cursor: pointer; }
      .input .radios .radio label {
        flex: 1 1 100%;
        font-size: 0.875em;
        cursor: pointer; }
  .input textarea {
    width: 100%;
    flex: 1 1 100%;
    height: 7.5rem;
    border-radius: 4px;
    border: solid 1px #666666;
    background: #fff;
    padding: 0.5rem 1em;
    min-width: 0;
    font-size: 0.875em;
    font-weight: 300;
    color: #333; }
  .input .file-input {
    flex: 1 1 100%; }
    .input .file-input input {
      opacity: 0;
      width: 0;
      height: 0;
      visibility: hidden;
      margin: 0;
      padding: 0; }
    .input .file-input button {
      flex: 0 0 6.875rem;
      width: 6.875rem;
      margin-left: 0;
      margin-right: 1rem; }
    .input .file-input .file-input__files {
      padding-top: 0.55rem; }
      .input .file-input .file-input__files .empty {
        font-size: 0.875em;
        font-weight: 300;
        display: block;
        line-height: 1.4em; }
      .input .file-input .file-input__files .file-input__files__file {
        margin-bottom: 0.675rem; }
        .input .file-input .file-input__files .file-input__files__file .file-name {
          font-size: 0.875em;
          font-weight: 300;
          display: block;
          line-height: 1.4em; }
        .input .file-input .file-input__files .file-input__files__file .file-size {
          font-size: 0.825em;
          font-weight: 500;
          display: block;
          line-height: 1.4em; }

.info-row {
  width: 100%;
  align-items: stretch;
  margin-bottom: 0.33rem; }
  .info-row .info-row__label {
    flex: 1 1 40%;
    font-size: 0.875em;
    color: #333;
    font-weight: 300;
    background: #ededed;
    padding: 0.65rem 1rem;
    margin-right: 0.33rem; }
    .info-row .info-row__label .duplicate-warning {
      margin-left: auto;
      margin-right: 0;
      position: relative;
      float: right; }
      .info-row .info-row__label .duplicate-warning img {
        width: 1rem;
        height: 1rem; }
      .info-row .info-row__label .duplicate-warning .text {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(90%, 0);
        background: #fff;
        padding: 1rem;
        z-index: 200;
        box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
        pointer-events: none;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 200ms linear, transform 200ms linear; }
      .info-row .info-row__label .duplicate-warning:hover .text {
        transform: translate(100%, 0);
        opacity: 1;
        pointer-events: all; }
  .info-row .info-row__value {
    flex: 1 1 60%;
    border: solid 1px #ededed;
    background: #f9f9f9;
    padding: 0.65rem 1rem;
    font-size: 0.875em;
    color: #333;
    font-weight: 300; }
  .info-row:last-child {
    margin-bottom: 0; }

/* ===================================================================== Login === */
.login {
  height: 100vh;
  perspective: 50rem; }

.login-form {
  background: #fff;
  border-radius: 0.45rem;
  overflow: hidden;
  border: solid 1px #ccc;
  min-width: 26.625rem;
  animation: twist-in 400ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  .login-form .login-form__logo {
    background: #f1f1f1;
    padding: 0.45rem 2rem;
    border-bottom: solid 1px #ccc; }
    .login-form .login-form__logo img {
      width: 100%;
      will-change: transform;
      transform: scale(1); }
  .login-form form {
    padding: 1.75rem 3rem; }
    .login-form form button {
      margin-top: 2rem; }

/* ====================================================================== Root === */
.root {
  height: 100vh;
  width: 100%;
  overflow: hidden; }

.nav {
  height: inherit;
  flex: 0 0 20rem;
  min-width: 0;
  animation: slide-fade-in-left 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  background: #333333;
  transition: all 230ms cubic-bezier(0.215, 0.61, 0.355, 1);
  z-index: 10; }
  @media only screen and (max-width: 1600px) {
    .nav {
      flex: 0 0 15rem; } }
  .nav .nav__logo-space {
    flex: 0 0 10.625rem;
    border-bottom: solid 1px #232323;
    justify-content: flex-end;
    padding: 0 0 2.5rem 1.625rem;
    width: 100%; }
    .nav .nav__logo-space img {
      width: 4.375rem;
      height: 3.875rem;
      transition: transform 230ms cubic-bezier(0.215, 0.61, 0.355, 1);
      transform-origin: left bottom; }
  .nav .nav__menu {
    width: 100%;
    overflow: hidden; }
    .nav .nav__menu a {
      width: 100%;
      padding: 0.5rem 1.625rem;
      align-items: center;
      transition: background 120ms linear;
      position: relative; }
      .nav .nav__menu a::before {
        content: "";
        display: block;
        position: absolute;
        position: absolute;
        top: 0;
        right: auto;
        bottom: 0;
        left: 0;
        width: 0.5rem;
        background: #e2211c;
        transform: translate(-100%, 0);
        transition: transform 120ms linear; }
      .nav .nav__menu a:hover {
        background: #1a1a1a; }
      .nav .nav__menu a.active {
        background: #1a1a1a; }
        .nav .nav__menu a.active::before {
          transform: translate(0, 0); }
      .nav .nav__menu a img {
        flex: 0 0 2.25rem;
        height: 2.25rem;
        margin-right: 0.5rem; }
      .nav .nav__menu a span {
        flex: 1 1 100%;
        white-space: nowrap;
        color: #fff;
        font-weight: 300;
        font-size: 1em;
        transition: opacity 230ms linear, transform 230ms linear; }
  .nav .nav__collapse-toggle {
    position: absolute;
    top: 0;
    right: -0.5rem;
    bottom: 0;
    left: auto;
    width: 1rem;
    cursor: pointer;
    opacity: 0;
    transition: opacity 120ms linear;
    background: rgba(255, 255, 255, 0.35); }
    .nav .nav__collapse-toggle:hover {
      opacity: 1; }
    .nav .nav__collapse-toggle .nav__collapse-toggle__icon {
      width: 1.25rem;
      background: #fff;
      border-radius: 50%;
      height: 1.25rem;
      text-align: center;
      pointer-events: none;
      position: relative;
      filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.3)); }
      .nav .nav__collapse-toggle .nav__collapse-toggle__icon img {
        pointer-events: none;
        width: 0.35rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-60%, -50%); }
  .nav[data-collapsed="true"] {
    flex: 0 0 5.25rem; }
    .nav[data-collapsed="true"] .nav__logo-space img {
      transform: translate(-0.675rem, 0) scale(0.8); }
    .nav[data-collapsed="true"] .nav__menu .nav__menu__item span {
      opacity: 0;
      transform: translate(-2rem, 0); }
    .nav[data-collapsed="true"] .nav__collapse-toggle__icon {
      transform: rotate(180deg); }

.root__routes {
  height: inherit;
  flex: 1 1 100%;
  overflow-y: scroll;
  animation: slide-fade-in-right 400ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  .root__routes .page {
    width: 100%; }

/* ===================================================================== Modal === */
.modal {
  backface-visibility: hidden;
  perspective: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90000;
  overflow-y: scroll;
  justify-content: flex-start;
  background: rgba(0, 0, 0, 0.5);
  animation: fade-in 400ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  .modal .modal__close {
    position: absolute;
    top: 0;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 250;
    height: 100%; }
  .modal .modal__view {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 500;
    background: #fff;
    overflow: hidden;
    border-radius: 0.3rem;
    box-shadow: 0 0 16px -2px rgba(0, 0, 0, 0.45);
    animation: slide-fade-in-bottom 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
    width: 100%;
    height: 100%;
    margin-top: 6.5%;
    margin-bottom: 6.5%;
    max-width: 32.5rem;
    margin-left: 20rem;
    border: solid 1px #333333; }
    @media only screen and (max-width: 1600px) {
      .modal .modal__view {
        margin-left: 5.25rem; } }
    .modal .modal__view .modal__top {
      background: #f1f1f1;
      border-bottom: solid 1px #ccc;
      position: relative;
      padding: 1.875rem 2.75rem 1.25rem;
      width: 100%;
      flex: 0 0 auto; }
      .modal .modal__view .modal__top .modal__title {
        font-size: 1.25em;
        font-weight: 300; }
      .modal .modal__view .modal__top .back-button {
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translate(0, -50%);
        width: 1.75rem;
        height: 1.75rem;
        cursor: pointer;
        padding: 0.25rem;
        border-radius: 50%; }
        .modal .modal__view .modal__top .back-button:hover {
          background: rgba(0, 0, 0, 0.05); }
        .modal .modal__view .modal__top .back-button:active {
          background: rgba(0, 0, 0, 0.1); }
    .modal .modal__view .modal__content {
      flex: 1 1 100%;
      overflow-y: scroll; }

.root__routes[data-nav-collapsed="true"] .modal__view {
  margin-left: 5.25rem; }

/* ================================================== Common Module Components === */
.page {
  animation: fade-in 250ms linear forwards; }

.page-title {
  flex: 0 0 6.725rem;
  width: 100%;
  background: #ededed;
  justify-content: flex-end;
  padding-bottom: 1.2rem; }
  .page-title h1 {
    font-size: 1.325em;
    color: #333;
    font-weight: 300; }

.account-bar {
  flex: 0 0 3.880rem;
  width: 100%;
  background: #f9f9f9;
  border-top: solid 1px #ccc;
  border-bottom: solid 1px #ccc; }
  .account-bar .content {
    height: 3.880rem;
    position: relative;
    align-items: center;
    justify-content: space-between; }
    .account-bar .content .account-bar__user {
      align-items: center; }
      .account-bar .content .account-bar__user .account-bar__user__icon {
        background: #ffffff;
        background: -moz-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
        background: -webkit-linear-gradient(top, #ffffff 0%, #f2f2f2 100%);
        background: linear-gradient(to bottom, #ffffff 0%, #f2f2f2 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2',GradientType=0 );
        border: solid 1px #ccc;
        border-radius: 0.3rem;
        margin-right: 0.675rem;
        width: 3.5rem;
        height: 2.25rem; }
        .account-bar .content .account-bar__user .account-bar__user__icon img {
          width: 1.5rem;
          height: 1.325rem; }
    .account-bar .content .button {
      margin-right: 0;
      border: solid 1px #e2211c;
      color: #e2211c;
      background: #f9f9f9;
      min-width: 9.5rem; }
      .account-bar .content .button:hover {
        background: #ececec; }
      .account-bar .content .button:active {
        background: #e0e0e0; }
      .account-bar .content .button .icon {
        width: 1rem;
        height: 1.1875rem;
        right: 1rem; }

.tabs {
  background: #f9f9f9;
  padding-top: 4rem;
  width: 100%; }
  .tabs.query-tabs {
    padding-top: 1.5rem; }
  .tabs .content {
    position: relative; }
    .tabs .content a[title="Add New"] {
      position: absolute;
      bottom: 0.5rem;
      right: 3rem; }
    .tabs .content a[title="Back"] {
      position: absolute;
      bottom: 0.5rem;
      left: 3rem; }
      .tabs .content a[title="Back"] .button {
        background: #f9f9f9;
        color: #e2211c;
        border: solid 1px #e2211c;
        min-width: 7.5rem;
        padding-left: 2rem; }
        .tabs .content a[title="Back"] .button:hover {
          background: #f1f1f1; }
        .tabs .content a[title="Back"] .button .icon {
          left: 1rem;
          width: 1rem; }
  .tabs .flex-row {
    justify-content: center;
    border-bottom: solid 1px #333333;
    position: relative; }
  .tabs .tabs__tab {
    width: 13.875rem;
    height: 2.8125rem;
    background: #ededed;
    text-align: center;
    margin: 0 0.2rem;
    padding: 0.85rem 0;
    border-radius: 0.3rem 0.3rem 0 0;
    color: #999;
    font-size: 0.9375rem;
    transition: background 120ms linear;
    cursor: pointer;
    user-select: none; }
    .tabs .tabs__tab.tabs__tab--small {
      width: 8rem; }
    .tabs .tabs__tab:hover {
      background: #d4d4d4; }
    .tabs .tabs__tab[data-active="true"] {
      background: #333333;
      color: #fff; }
    .tabs .tabs__tab[data-inactive="true"] {
      color: #ccc; }
      .tabs .tabs__tab[data-inactive="true"]:hover {
        background: #ededed; }

.table .table__tr {
  align-items: stretch;
  border-bottom: solid 1px #ccc;
  position: relative; }
  .table .table__tr[data-yourmove="true"] {
    background: rgba(226, 33, 28, 0.2); }
    .table .table__tr[data-yourmove="true"]:hover {
      background: rgba(226, 33, 28, 0.3); }
  .table .table__tr:last-child {
    border-bottom: none; }

.table a.table__tr {
  transition: background-color 120ms linear; }
  .table a.table__tr:hover {
    background: #f2f2f2; }

.table .table__td {
  flex: 1 1 100%;
  font-size: 0.825em;
  padding: 0.75rem 1rem;
  border-right: solid 1px #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .table .table__td small {
    display: block;
    font-size: 0.8em;
    line-height: 1.25em; }
  .table .table__td.center {
    align-items: center; }
  .table .table__td.small {
    flex: 0 0 10rem; }
  .table .table__td:last-child {
    border-right: 0 none; }
  .table .table__td .grey-button {
    margin-left: auto;
    margin-right: auto; }
  .table .table__td a {
    z-index: 10000;
    font-size: 0.875em;
    color: #e2211c;
    text-decoration: underline; }
    .table .table__td a:hover {
      color: #e84d49; }

.table .table__thead {
  width: 100%;
  border: solid 1px #999; }
  .table .table__thead .table__td {
    background: #f1f1f1;
    border-right: solid 1px #999;
    font-size: 0.875em; }
    .table .table__thead .table__td:last-child {
      border-right: 0 none; }

.table .table__tbody {
  width: 100%;
  border: solid 1px #ccc;
  border-top: 0 none; }
  .table .table__tbody .table__tr::before {
    content: "";
    display: block;
    position: absolute;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: solid 2px #00BB00;
    opacity: 0; }
  .table .table__tbody .table__tr[data-recent="true"]::before {
    animation: flash 2s linear; }

@keyframes flash {
  0% {
    opacity: 0; }
  25% {
    opacity: 1; }
  50% {
    opacity: 0; }
  75% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/* ================================================================= Query Log === */
.query-log-table {
  width: 100%;
  padding-top: 1.5rem;
  padding-bottom: 3.5rem; }
  .query-log-table .query-log-table__table {
    position: relative;
    transition: opacity 120ms linear;
    opacity: 1; }
    .query-log-table .query-log-table__table[data-busy="true"] {
      opacity: 0.5;
      pointer-events: none; }

.add-query-form {
  padding: 2.25rem 2.75rem; }
  .add-query-form .add-query-form__inputs {
    position: relative;
    transition: opacity 120ms linear;
    opacity: 1; }
    .add-query-form .add-query-form__inputs[data-busy="true"] {
      opacity: 0.5;
      pointer-events: none; }
  .add-query-form .button[type="submit"] {
    padding-right: 1.2rem;
    margin-top: 2rem; }
    .add-query-form .button[type="submit"] .icon {
      right: 0.75rem;
      width: 1.25rem; }

.filters {
  width: 100%;
  padding: 1.5rem 2.75rem 0;
  align-items: center;
  justify-content: space-between;
  overflow: hidden; }
  .filters .input {
    margin-bottom: 0; }
  .filters .home-sort {
    flex: 0 0 auto;
    margin-right: 1rem; }
    .filters .home-sort .input label {
      flex: 0 0 auto;
      margin-right: 1rem; }
    .filters .home-sort .select {
      min-width: 13.5rem; }
  .filters .home-user-filter {
    flex: 0 0 auto;
    margin-right: 0.5rem; }
    .filters .home-user-filter .input {
      margin-bottom: 0; }
      .filters .home-user-filter .input label {
        display: none; }
      .filters .home-user-filter .input .select {
        min-width: 10rem; }
  .filters .home-search {
    flex: 1 1 100%;
    align-items: center;
    justify-content: flex-end; }
    .filters .home-search .input {
      flex: 0 0 auto;
      width: auto;
      transform: translate(3rem, 0);
      transition: transform 120ms linear; }
      .filters .home-search .input label {
        flex: 0 0 5rem; }
      .filters .home-search .input .select {
        width: 13rem;
        margin-left: 1rem; }
    .filters .home-search .home-search__value {
      margin-left: 1rem; }
      .filters .home-search .home-search__value input {
        width: 17rem;
        flex: 0 0 auto;
        height: 2.35rem;
        border-radius: 4px;
        border: solid 1px #666666;
        background: #fff;
        padding: 0 1em;
        min-width: 0;
        font-size: 0.875em;
        font-weight: 300;
        color: #333;
        transform: translate(3rem, 0);
        transition: transform 120ms linear; }
  .filters button {
    flex: 0 0 2rem;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border-radius: 50%;
    margin-left: 1rem;
    text-align: center;
    opacity: 0;
    transform: translate(3rem, 0);
    pointer-events: none;
    transition: opacity 120ms linear, transform 120ms linear;
    position: relative; }
    .filters button img {
      width: 75%;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .filters button:hover {
      background: #e0e0e0; }
  .filters[data-active="true"] .home-search .input {
    transform: translate(0, 0); }
  .filters[data-active="true"] .home-search .home-search__value input {
    transform: translate(0, 0); }
  .filters[data-active="true"] button {
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: all; }

.query-details {
  height: 100vh; }
  .query-details .query-details__container {
    width: 100%;
    background: #f9f9f9;
    flex: 1 1 100%;
    position: relative; }
  .query-details .query-details__content {
    width: 100%;
    min-width: 68rem;
    height: 100%; }
  .query-details .query-details__stages {
    flex: 1 1 100%;
    width: calc(100% - 6rem);
    margin-left: 3rem;
    background: #fff;
    position: relative; }
    .query-details .query-details__stages > div {
      min-height: 100%; }
    .query-details .query-details__stages .query-details__stages__content {
      padding: 2rem 1.5rem 2rem 1.5rem; }
      .query-details .query-details__stages .query-details__stages__content[data-changing="changing"] .query-stage {
        animation: slide-fade-out-left 150ms linear forwards; }
  .query-details .query-details__actions {
    width: calc(100% - 6rem);
    margin-left: 3rem;
    padding: 1.25rem 0 1.75rem; }
    .query-details .query-details__actions .button {
      background: #f9f9f9;
      border: solid 1px #e2211c;
      color: #e2211c;
      min-width: 7.5rem;
      padding-right: 2.5rem;
      margin: 0 0.25rem; }
      .query-details .query-details__actions .button .icon {
        right: 1rem;
        width: 1rem; }
      .query-details .query-details__actions .button[data-direction="backwards"] {
        padding-right: 1rem;
        padding-left: 2.5rem; }
        .query-details .query-details__actions .button[data-direction="backwards"] .icon {
          right: auto;
          left: 1rem; }
      .query-details .query-details__actions .button:hover {
        background: #f1f1f1; }

.query-stage {
  animation: slide-fade-in-right 150ms linear forwards; }
  .query-stage .title {
    display: block;
    font-size: 1em;
    color: #333;
    padding-bottom: 0.75rem;
    margin-bottom: 1.375rem;
    border-bottom: solid 1px #ccc; }
  .query-stage .half:first-child {
    margin-right: 2rem; }
  .query-stage .input:not(.file) label {
    flex: 1 1 40%;
    font-size: 0.825em;
    color: #333;
    font-weight: 300;
    background: #ededed;
    padding: 0.65rem 1rem;
    margin-right: 0.33rem; }
    .query-stage .input:not(.file) label .duplicate-warning {
      margin-left: auto;
      margin-right: 0;
      position: relative;
      float: right; }
      .query-stage .input:not(.file) label .duplicate-warning img {
        width: 1rem;
        height: 1rem; }
      .query-stage .input:not(.file) label .duplicate-warning .text {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(90%, 0);
        background: #fff;
        padding: 1rem;
        z-index: 200;
        box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
        pointer-events: none;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 200ms linear, transform 200ms linear; }
      .query-stage .input:not(.file) label .duplicate-warning:hover .text {
        transform: translate(100%, 0);
        opacity: 1;
        pointer-events: all; }
  .query-stage .input:not(.file) input,
  .query-stage .input .select {
    flex: 1 1 60%; }
  .query-stage .attachments {
    margin-top: 2rem;
    width: calc(50% - 1rem);
    background: #f9f9f9;
    border: solid 1px #ededed;
    padding: 2rem 1rem; }
    .query-stage .attachments .subtitle {
      display: block;
      font-size: 0.925em;
      color: #333;
      margin-bottom: 1.25rem; }
    .query-stage .attachments a {
      font-size: 0.875em;
      color: #e2211c;
      text-decoration: underline; }
      .query-stage .attachments a:hover {
        color: #e84d49; }
    .query-stage .attachments ul {
      margin-bottom: 1.5rem; }
      .query-stage .attachments ul li a {
        display: inline-block;
        line-height: 1.5em;
        vertical-align: text-bottom; }
    .query-stage .attachments button {
      margin-left: 0; }
      .query-stage .attachments button img {
        width: 1rem;
        height: 1rem;
        pointer-events: none;
        margin-right: 5px;
        padding: 0; }
    .query-stage .attachments.no-input {
      padding: 1rem; }
      .query-stage .attachments.no-input .subtitle {
        margin-bottom: 0.5rem; }
      .query-stage .attachments.no-input ul {
        margin-bottom: 0; }

.checkboxes {
  margin-top: 1rem; }

.query-comments {
  position: fixed;
  bottom: 1.75rem;
  right: 3rem;
  width: 18rem;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0 0 10px -1px rgba(0, 0, 0, 0.3);
  border: solid 1px #333333;
  z-index: 200; }
  .query-comments .query-comments__toggle {
    background: #333333;
    align-items: center;
    justify-content: space-between;
    padding: 0.65rem 0.875rem;
    width: 100%;
    transition: background 120ms linear; }
    .query-comments .query-comments__toggle:hover {
      background: #1a1a1a; }
    .query-comments .query-comments__toggle img:first-child {
      width: 1.25rem;
      height: 1.25rem; }
    .query-comments .query-comments__toggle span {
      font-size: 0.875em;
      color: #f9f9f9;
      margin-left: 1.5rem;
      margin-right: auto; }
    .query-comments .query-comments__toggle img:last-child {
      margin-left: auto;
      width: 0.675rem;
      height: 0.35rem; }
  .query-comments .query-comments__container {
    height: 0;
    overflow: hidden;
    transition: all 180ms linear; }
    .query-comments .query-comments__container .query-comments__list {
      width: 100%;
      flex: 1 1 100%;
      position: relative;
      background: #fff; }
      .query-comments .query-comments__container .query-comments__list > div {
        min-height: 100%; }
      .query-comments .query-comments__container .query-comments__list .comment {
        padding: 1rem 1.5rem;
        border-bottom: solid 1px #ededed; }
        .query-comments .query-comments__container .query-comments__list .comment .comment__author {
          display: block;
          font-size: 0.75em;
          color: #e2211c;
          line-height: 1.3em;
          font-weight: 500; }
        .query-comments .query-comments__container .query-comments__list .comment .comment__date {
          display: block;
          font-size: 0.675em;
          color: #333333;
          line-height: 1.3em;
          margin-bottom: 0.65rem; }
        .query-comments .query-comments__container .query-comments__list .comment p {
          color: #333333;
          font-size: 0.75em;
          line-height: 1.4em; }
    .query-comments .query-comments__container .query-comments__input {
      width: 100%;
      flex: 0 0 auto;
      padding: 0.5rem 1.5rem;
      background: #ededed; }
      .query-comments .query-comments__container .query-comments__input textarea {
        background: none;
        width: 100%;
        font-size: 0.825em;
        color: #333333;
        height: 1rem;
        min-height: 1rem;
        max-height: 4rem; }
  .query-comments[data-visible="true"] .query-comments__container {
    height: 16rem; }

.price-query-stage-2 .spreadsheet-upload {
  margin-top: 2rem; }
  .price-query-stage-2 .spreadsheet-upload .input {
    justify-content: flex-start;
    flex: 0 0 24.5rem; }
  .price-query-stage-2 .spreadsheet-upload .input.file label {
    flex: 0 0 10rem; }
  .price-query-stage-2 .spreadsheet-upload .input .file-input {
    flex: 0 0 auto; }
  .price-query-stage-2 .spreadsheet-upload .button {
    margin-left: 1rem;
    border-radius: 0;
    min-width: 7rem; }
    .price-query-stage-2 .spreadsheet-upload .button span {
      font-size: 0.875em; }

.ipc-table {
  margin-top: 1rem; }
  .ipc-table .ipc-table__row {
    margin-bottom: 0.25rem;
    align-items: stretch; }
    .ipc-table .ipc-table__row[data-deleted="true"] {
      transition: opacity 200ms linear;
      opacity: 0; }
    .ipc-table .ipc-table__row[data-move-up="true"] {
      transition: transform 200ms linear;
      transition-delay: 200ms;
      transform: translate(0, calc(-100% - 0.25rem)); }
  .ipc-table .ipc-table__col {
    flex: 1 1 calc(100% / 9);
    text-align: right;
    margin-right: 0.25rem;
    font-size: 0.825em;
    color: #333333;
    padding: 0.5rem;
    border: solid 1px #ededed; }
    .ipc-table .ipc-table__col.flex {
      align-items: center;
      padding: 0.35rem 0.5rem; }
    .ipc-table .ipc-table__col button {
      margin-right: 0.35rem;
      padding: 0; }
      .ipc-table .ipc-table__col button img {
        width: 1rem;
        height: 1rem;
        pointer-events: none; }
    .ipc-table .ipc-table__col.small {
      flex: 0 0 5rem;
      width: 5rem; }
    .ipc-table .ipc-table__col.mid-small {
      flex: 0 0 7rem;
      width: 7rem; }
    .ipc-table .ipc-table__col.mid-mid-small {
      flex: 0 0 8.5rem;
      width: 8.5rem; }
    @media only screen and (max-width: 1600px) {
      .ipc-table .ipc-table__col {
        font-size: 0.75em; } }
    .ipc-table .ipc-table__col:first-child {
      text-align: left; }
    .ipc-table .ipc-table__col:last-child {
      margin-right: 0; }
    .ipc-table .ipc-table__col.billRef {
      position: relative; }
    .ipc-table .ipc-table__col .duplicate-warning {
      margin-left: auto;
      margin-right: 0;
      position: relative; }
      .ipc-table .ipc-table__col .duplicate-warning img {
        width: 1rem;
        height: 1rem; }
      .ipc-table .ipc-table__col .duplicate-warning .text {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(90%, 0);
        background: #fff;
        padding: 1rem;
        z-index: 200;
        box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.5);
        pointer-events: none;
        white-space: nowrap;
        opacity: 0;
        transition: opacity 200ms linear, transform 200ms linear; }
      .ipc-table .ipc-table__col .duplicate-warning:hover .text {
        transform: translate(100%, 0);
        opacity: 1;
        pointer-events: all; }
  .ipc-table .ipc-row-input {
    margin-top: 1rem;
    padding-right: 7.25rem; }
    .ipc-table .ipc-row-input.edit {
      margin-top: 0;
      margin-bottom: 0.25rem; }
  .ipc-table .space-filler {
    background: #f9f9f9;
    border: solid 1px #f9f9f9;
    flex: 1 1 100%;
    margin-right: 0.25rem;
    height: 2.2rem; }
  .ipc-table .ipc-row-input .row-input {
    flex: 1 1 100%;
    text-align: left;
    margin-right: 0.25rem;
    font-size: 0.825em;
    color: #333333;
    padding: 0.35rem 0.5rem;
    border: solid 1px #333333; }
    .ipc-table .ipc-row-input .row-input input, .ipc-table .ipc-row-input .row-input select {
      display: block;
      width: 100%;
      height: 1.5rem;
      padding: 0; }
    .ipc-table .ipc-row-input .row-input input[type="number"] {
      -moz-appearance: textfield; }
    .ipc-table .ipc-row-input .row-input input::-webkit-outer-spin-button,
    .ipc-table .ipc-row-input .row-input input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .ipc-table .ipc-row-input .row-input select {
      -webkit-appearance: menulist;
      -moz-appearance: menulist; }
    .ipc-table .ipc-row-input .row-input:first-child input {
      text-align: left; }
    .ipc-table .ipc-row-input .row-input:last-child {
      margin-right: 0; }
    .ipc-table .ipc-row-input .row-input.small {
      flex: 0 0 5rem;
      width: 5rem; }
    .ipc-table .ipc-row-input .row-input.mid-small {
      flex: 0 0 7rem;
      width: 7rem; }
    .ipc-table .ipc-row-input .row-input.mid-mid-small {
      flex: 0 0 8.5rem;
      width: 8.5rem; }
  .ipc-table .ipc-row-input .add {
    flex: 1 1 calc(100% / 4.25); }
    .ipc-table .ipc-row-input .add .button {
      margin-left: 0;
      border-radius: 0; }
  .ipc-table .ipc-table__head .ipc-table__col {
    background: #ededed; }

.pod-ipc-table .ipc-table__row {
  padding-right: 10.5rem; }

.pod-ipc-table .ipc-row-input {
  padding-right: 0; }

.pod-ipc-graphic {
  margin: 0 auto;
  width: 100%;
  padding-right: 10.5rem; }
  .pod-ipc-graphic img {
    display: block;
    margin: 0 auto;
    max-width: 800px; }

.query-stage .pod-query-stage-2 .input {
  margin-bottom: 0.5rem; }
  .query-stage .pod-query-stage-2 .input input {
    flex: 1 1 50%;
    margin-left: 1rem; }

.query-stage .pod-query-stage-2 .radios {
  flex: 1 1 50%;
  margin-left: 1rem; }
  .query-stage .pod-query-stage-2 .radios .radio label {
    background: none;
    padding: 0; }

.query-stage-3 .stage-3-form > .input:first-child,
.query-stage-3 .stage-3-form > .info-row:first-child {
  max-width: 50%;
  margin-bottom: 2rem;
  margin-right: 2rem; }

.query-stage-3 .stage-3-form .half:first-child {
  margin-right: 2rem;
  margin-bottom: 0.5rem; }

.query-stage-3 .stage-3-form .hidden-inputs {
  margin-bottom: 0.5rem; }

.query-stage-3 .stage-3-form .input {
  margin-bottom: 0.5rem; }
  .query-stage-3 .stage-3-form .input label {
    flex: 1 1 100%;
    width: 100%;
    margin-right: 0; }
  .query-stage-3 .stage-3-form .input input, .query-stage-3 .stage-3-form .input .select {
    flex: 1 1 100%;
    margin-left: 1rem; }

.query-stage-3 .stage-3-form .radios {
  margin-left: 1rem; }

.query-stage-3 .stage-3-form .radio label {
  background: none;
  padding: 0; }

.query-stage-3 .stage-3-form .radio input {
  margin-left: 0; }

.query-stage-3 .stage-3-form .table {
  margin-top: 1.25rem; }
  .query-stage-3 .stage-3-form .table .table__thead .table__td {
    font-size: 0.875em; }
  .query-stage-3 .stage-3-form .table .table__tbody .table__td:first-child {
    background: #f9f9f9; }

.query-stage-3 .stage-3-form .approve {
  margin-top: 2rem;
  justify-content: flex-start;
  align-items: center; }
  .query-stage-3 .stage-3-form .approve > span {
    margin-right: 1.5rem; }
  .query-stage-3 .stage-3-form .approve .button {
    border-radius: 0;
    margin-left: 0;
    margin-right: 0.5rem; }

div[class*="stage-2"] .button.close-button {
  margin-top: 2rem; }

div[class*="stage-4"] .input {
  margin-bottom: 0.5rem; }
  div[class*="stage-4"] .input input {
    flex: 1 1 50%;
    margin-left: 1rem; }
  div[class*="stage-4"] .input .select {
    margin-left: 1rem; }

div[class*="stage-4"] .radios {
  flex: 1 1 50%;
  margin-left: 1rem; }
  div[class*="stage-4"] .radios .radio label {
    background: none;
    padding: 0; }

div[class*="stage-4"] .button {
  margin-top: 2rem; }
